import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useErrors } from './useErrors';
import { UPDATE_ACCOUNT } from '../mutations/update-account-mutation';
import { Context } from '../store';
import { ACTIONS_TYPES } from '../constants';
import { useShowToast } from './useShowToast';
import { zipCodeService } from '../services';

export const useUpdateAccount = () => {
  const [createAccountMutation] = useMutation(UPDATE_ACCOUNT);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(Context);
  const { showSuccess } = useShowToast();

  const updateAccount = async ({
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
    phone,
    orderNotificationsType,
  }) => {
    try {
      setLoading(true);
      const correctPhone = phone?.replace(/[\s()_]/g, '');

      const response = await createAccountMutation({
        variables: {
          input: {
            formalName: `${firstName.trim()} ${lastName.trim()}`,
            phone: correctPhone?.length > 2 ? correctPhone : null,
            configInput: {
              orderNotificationsType,
            },
            addressInput: {
              type: 'shipping',
              streetLine1: addressLine1,
              streetLine2: addressLine2,
              city,
              stateCode: state,
              postalCode: zipCode,
              countryCode: country,
            },
          },
        },
      });

      const data = response?.data?.updateBuyer;

      setLoading(false);

      dispatch({ type: ACTIONS_TYPES.UPDATE_ACCOUNT, payload: data });
      dispatch({ type: ACTIONS_TYPES.UPDATE_ZIP_CODE, payload: zipCode });
      zipCodeService.zipCode = zipCode;
      showSuccess('Update Account Settings has been success');
      return data ?? null;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
      return null;
    }
  };

  return {
    loading,
    updateAccount,
  };
};
