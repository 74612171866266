import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useErrors } from './useErrors';

export const CREATE_ORDER_ACCOUNT = gql`
  mutation createOrderAccount($input: CreateAccountInput!) {
    createOrderBuyer(buyerInput: $input) {
      identifier
      uid
    }
  }
`;

export const useCreateOrderAccount = () => {
  const [createOrderAccountMutation] = useMutation(CREATE_ORDER_ACCOUNT);
  const { errorsHandler } = useErrors();
  const [loading, setLoading] = useState(false);

  const createOrderAccount = async ({ formalName, email, phone, orderNotificationsType }) => {
    try {
      setLoading(true);
      const correctPhone = phone?.replace(/[\s()_]/g, '');

      const response = await createOrderAccountMutation({
        variables: {
          input: {
            formalName,
            email,
            phone: correctPhone?.length > 2 ? correctPhone : null,
            configInput: {
              orderNotificationsType,
            },
          },
        },
      });

      setLoading(false);

      return response?.data?.createOrderBuyer;
    } catch (error) {
      errorsHandler(error);
      setLoading(false);
      throw new Error();
    }
  };

  return {
    loading,
    createOrderAccount,
  };
};
